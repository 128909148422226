.theme-purple ::selection {
	color: #fff;
	background: #8565ff
}

.theme-purple .page-loader-wrapper {
	background: #8565ff
}

.theme-purple #left-sidebar .nav-tabs .nav-link.active {
	color: #8565ff
}

.theme-purple:before,
.theme-purple:after {
	background: #8565ff
}

.theme-purple #wrapper:before,
.theme-purple #wrapper:after {
	background: #8565ff
}

.theme-purple .sidebar-nav .metismenu>li.active>a {
	border-left-color: #8565ff
}

.theme-purple .main_menu .navbar .navbar-nav .nav-item.active>a {
	color: #8565ff
}

.theme-purple .main_menu .navbar .navbar-nav .nav-item .nav-link:hover {
	color: #8565ff
}

.theme-purple .main_menu .navbar .navbar-nav .dropdown-menu {
	border-color: #8565ff
}

.theme-purple .main_menu .navbar .navbar-nav .dropdown-menu li.active a {
	color: #8565ff
}

.theme-purple .nav-tabs-new>li>a:hover,
.theme-purple .nav-tabs-new>li>a:focus {
	background-color: #8565ff;
	color: #fff
}

.theme-purple .nav-tabs-new>li>a.active {
	border-color: #8565ff !important;
	background: #8565ff;
	color: #fff
}

.theme-purple .navbar-nav ul.menu-icon {
	background: #8565ff
}

.theme-purple .fancy-checkbox input[type="checkbox"]:checked+span:before {
	background: #8565ff;
	border-color: #8565ff
}

.theme-purple .chat-widget li.right .chat-info {
	background: #8565ff;
	color: #fff
}

.theme-purple .chat-widget li.right .chat-info:before {
	border-left: 10px solid #8565ff
}

.theme-purple .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header {
	background: #8565ff;
	border-color: #8565ff;
	color: #fff
}

.theme-purple .blog-page .single_post .footer .stats li a:hover {
	color: #8565ff
}

.theme-purple .auth-main .btn-primary {
	background: #8565ff;
	border-color: #8565ff
}

.theme-purple .sidebar-nav .metismenu>li i {
	color: #8565ff
}

.theme-purple .right_chat li a:hover .media-object {
	border-color: #8565ff
}

.theme-purple .chat_app .chat .chat-history .other-message {
	background: #8565ff;
	color: #fff
}

.theme-purple .chat_app .chat .chat-history .other-message::after {
	border-bottom-color: #8565ff
}

.theme-purple .chat_app .people_list .chat-list li.active,
.theme-purple .chat_app .people_list .chat-list li:hover {
	border-color: #8565ff
}

.theme-blue ::selection {
	color: #fff;
	background: #449cff
}

.theme-blue .page-loader-wrapper {
	background: #449cff
}

.theme-blue #left-sidebar .nav-tabs .nav-link.active {
	color: #449cff
}

.theme-blue:before,
.theme-blue:after {
	background: #449cff
}

.theme-blue #wrapper:before,
.theme-blue #wrapper:after {
	background: #449cff
}

.theme-blue .sidebar-nav .metismenu>li.active>a {
	border-left-color: #449cff
}

.theme-blue .navbar-nav ul.menu-icon {
	background: #449cff
}

.theme-blue .main_menu .navbar .navbar-nav .nav-item.active>a {
	color: #449cff
}

.theme-blue .main_menu .navbar .navbar-nav .nav-item .nav-link:hover {
	color: #449cff
}

.theme-blue .main_menu .navbar .navbar-nav .dropdown-menu {
	border-color: #449cff
}

.theme-blue .main_menu .navbar .navbar-nav .dropdown-menu li.active a {
	color: #449cff
}

.theme-blue .nav-tabs-new>li>a:hover,
.theme-blue .nav-tabs-new>li>a:focus {
	background-color: #449cff;
	color: #fff
}

.theme-blue .nav-tabs-new>li>a.active {
	border-color: #449cff !important;
	background: #449cff;
	color: #fff
}

.theme-blue .fancy-checkbox input[type="checkbox"]:checked+span:before {
	background: #449cff;
	border-color: #449cff
}

.theme-blue .chat-widget li.right .chat-info {
	background: #449cff;
	color: #fff
}

.theme-blue .chat-widget li.right .chat-info:before {
	border-left: 10px solid #449cff
}

.theme-blue .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header {
	background: #449cff;
	border-color: #449cff;
	color: #fff
}

.theme-blue .blog-page .single_post .footer .stats li a:hover {
	color: #449cff
}

.theme-blue .auth-main .btn-primary {
	background: #449cff;
	border-color: #449cff
}

.theme-blue .sidebar-nav .metismenu>li i {
	color: #449cff
}

.theme-blue .right_chat li a:hover .media-object {
	border-color: #449cff
}

.theme-blue .chat_app .chat .chat-history .other-message {
	background: #449cff;
	color: #fff
}

.theme-blue .chat_app .chat .chat-history .other-message::after {
	border-bottom-color: #449cff
}

.theme-blue .chat_app .people_list .chat-list li.active,
.theme-blue .chat_app .people_list .chat-list li:hover {
	border-color: #449cff
}

.theme-cyan ::selection {
	color: #fff;
	background: #2eacb3
}

.theme-cyan .page-loader-wrapper {
	background: #2eacb3
}

.theme-cyan #left-sidebar .nav-tabs .nav-link.active {
	color: #2eacb3
}

.theme-cyan:before,
.theme-cyan:after {
	background: #2eacb3
}

.theme-cyan #wrapper:before,
.theme-cyan #wrapper:after {
	background: #2eacb3
}

.theme-cyan .sidebar-nav .metismenu>li.active>a {
	border-left-color: #2eacb3
}

.theme-cyan .main_menu .navbar .navbar-nav .nav-item.active>a {
	color: #2eacb3
}

.theme-cyan .main_menu .navbar .navbar-nav .nav-item .nav-link:hover {
	color: #2eacb3
}

.theme-cyan .main_menu .navbar .navbar-nav .dropdown-menu {
	border-color: #2eacb3
}

.theme-cyan .main_menu .navbar .navbar-nav .dropdown-menu li.active a {
	color: #2eacb3
}

.theme-cyan .nav-tabs-new>li>a:hover,
.theme-cyan .nav-tabs-new>li>a:focus {
	background-color: #2eacb3;
	color: #fff
}

.theme-cyan .nav-tabs-new>li>a.active {
	border-color: #2eacb3 !important;
	background: #2eacb3;
	color: #fff
}

.theme-cyan .navbar-nav ul.menu-icon {
	background: #2eacb3
}

.theme-cyan .fancy-checkbox input[type="checkbox"]:checked+span:before {
	background: #2eacb3;
	border-color: #2eacb3
}

.theme-cyan .chat-widget li.right .chat-info {
	background: #2eacb3;
	color: #fff
}

.theme-cyan .chat-widget li.right .chat-info:before {
	border-left: 10px solid #2eacb3
}

.theme-cyan .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header {
	background: #2eacb3;
	border-color: #2eacb3;
	color: #fff
}

.theme-cyan .blog-page .single_post .footer .stats li a:hover {
	color: #2eacb3
}

.theme-cyan .auth-main .btn-primary {
	background: #2eacb3;
	border-color: #2eacb3
}

.theme-cyan .sidebar-nav .metismenu>li i {
	color: #2eacb3
}

.theme-cyan .right_chat li a:hover .media-object {
	border-color: #2eacb3
}

.theme-cyan .chat_app .chat .chat-history .other-message {
	background: #2eacb3;
	color: #fff
}

.theme-cyan .chat_app .chat .chat-history .other-message::after {
	border-bottom-color: #2eacb3
}

.theme-cyan .chat_app .people_list .chat-list li.active,
.theme-cyan .chat_app .people_list .chat-list li:hover {
	border-color: #2eacb3
}

.theme-green ::selection {
	color: #fff;
	background: #31b96f
}

.theme-green .page-loader-wrapper {
	background: #31b96f
}

.theme-green #left-sidebar .nav-tabs .nav-link.active {
	color: #31b96f
}

.theme-green:before,
.theme-green:after {
	background: #31b96f
}

.theme-green #wrapper:before,
.theme-green #wrapper:after {
	background: #31b96f
}

.theme-green .sidebar-nav .metismenu>li.active>a {
	border-left-color: #31b96f
}

.theme-green .main_menu .navbar .navbar-nav .nav-item.active>a {
	color: #31b96f
}

.theme-green .main_menu .navbar .navbar-nav .nav-item .nav-link:hover {
	color: #31b96f
}

.theme-green .main_menu .navbar .navbar-nav .dropdown-menu {
	border-color: #31b96f
}

.theme-green .main_menu .navbar .navbar-nav .dropdown-menu li.active a {
	color: #31b96f
}

.theme-green .nav-tabs-new>li>a:hover,
.theme-green .nav-tabs-new>li>a:focus {
	background-color: #31b96f;
	color: #fff
}

.theme-green .nav-tabs-new>li>a.active {
	border-color: #31b96f !important;
	background: #31b96f;
	color: #fff
}

.theme-green .navbar-nav ul.menu-icon {
	background: #31b96f
}

.theme-green .fancy-checkbox input[type="checkbox"]:checked+span:before {
	background: #31b96f;
	border-color: #31b96f
}

.theme-green .chat-widget li.right .chat-info {
	background: #31b96f;
	color: #fff
}

.theme-green .chat-widget li.right .chat-info:before {
	border-left: 10px solid #31b96f
}

.theme-green .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header {
	background: #31b96f;
	border-color: #31b96f;
	color: #fff
}

.theme-green .blog-page .single_post .footer .stats li a:hover {
	color: #31b96f
}

.theme-green .auth-main .btn-primary {
	background: #31b96f;
	border-color: #31b96f
}

.theme-green .sidebar-nav .metismenu>li i {
	color: #31b96f
}

.theme-green .right_chat li a:hover .media-object {
	border-color: #31b96f
}

.theme-green .chat_app .chat .chat-history .other-message {
	background: #31b96f;
	color: #fff
}

.theme-green .chat_app .chat .chat-history .other-message::after {
	border-bottom-color: #31b96f
}

.theme-green .chat_app .people_list .chat-list li.active,
.theme-green .chat_app .people_list .chat-list li:hover {
	border-color: #31b96f
}

.theme-orange ::selection {
	color: #fff;
	background: #f68c1f
}

.theme-orange .page-loader-wrapper {
	background: #f68c1f
}

.theme-orange #left-sidebar .nav-tabs .nav-link.active {
	color: #f68c1f
}

.theme-orange:before,
.theme-orange:after {
	background: #f68c1f
}

.theme-orange #wrapper:before,
.theme-orange #wrapper:after {
	background: #f68c1f
}

.theme-orange .sidebar-nav .metismenu>li.active>a {
	border-left-color: #f68c1f
}

.theme-orange .main_menu .navbar .navbar-nav .nav-item.active>a {
	color: #f68c1f
}

.theme-orange .main_menu .navbar .navbar-nav .nav-item .nav-link:hover {
	color: #f68c1f
}

.theme-orange .main_menu .navbar .navbar-nav .dropdown-menu {
	border-color: #f68c1f
}

.theme-orange .main_menu .navbar .navbar-nav .dropdown-menu li.active a {
	color: #f68c1f
}

.theme-orange .nav-tabs-new>li>a:hover,
.theme-orange .nav-tabs-new>li>a:focus {
	background-color: #f68c1f;
	color: #fff
}

.theme-orange .nav-tabs-new>li>a.active {
	border-color: #f68c1f !important;
	background: #f68c1f;
	color: #fff
}

.theme-orange .navbar-nav ul.menu-icon {
	background: #f68c1f
}

.theme-orange .fancy-checkbox input[type="checkbox"]:checked+span:before {
	background: #f68c1f;
	border-color: #f68c1f
}

.theme-orange .chat-widget li.right .chat-info {
	background: #f68c1f;
	color: #fff
}

.theme-orange .chat-widget li.right .chat-info:before {
	border-left: 10px solid #f68c1f
}

.theme-orange .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header {
	background: #f68c1f;
	border-color: #f68c1f;
	color: #fff
}

.theme-orange .blog-page .single_post .footer .stats li a:hover {
	color: #f68c1f
}

.theme-orange .auth-main .btn-primary {
	background: #f68c1f;
	border-color: #f68c1f
}

.theme-orange .sidebar-nav .metismenu>li i {
	color: #f68c1f
}

.theme-orange .right_chat li a:hover .media-object {
	border-color: #f68c1f
}

.theme-orange .chat_app .chat .chat-history .other-message {
	background: #f68c1f;
	color: #fff
}

.theme-orange .chat_app .chat .chat-history .other-message::after {
	border-bottom-color: #f68c1f
}

.theme-orange .chat_app .people_list .chat-list li.active,
.theme-orange .chat_app .people_list .chat-list li:hover {
	border-color: #f68c1f
}

.theme-blush ::selection {
	color: #fff;
	background: #ff5a7d
}

.theme-blush .page-loader-wrapper {
	background: #ff5a7d
}

.theme-blush #left-sidebar .nav-tabs .nav-link.active {
	color: #ff5a7d
}

.theme-blush:before,
.theme-blush:after {
	background: #ff5a7d
}

.theme-blush #wrapper:before,
.theme-blush #wrapper:after {
	background: #ff5a7d
}

.theme-blush .sidebar-nav .metismenu>li.active>a {
	border-left-color: #ff5a7d
}

.theme-blush .navbar-nav ul.menu-icon {
	background: #ff5a7d
}

.theme-blush .main_menu .navbar .navbar-nav .nav-item.active>a {
	color: #ff5a7d
}

.theme-blush .main_menu .navbar .navbar-nav .nav-item .nav-link:hover {
	color: #ff5a7d
}

.theme-blush .main_menu .navbar .navbar-nav .dropdown-menu {
	border-color: #ff5a7d
}

.theme-blush .main_menu .navbar .navbar-nav .dropdown-menu li.active a {
	color: #ff5a7d
}

.theme-blush .nav-tabs-new>li>a:hover,
.theme-blush .nav-tabs-new>li>a:focus {
	background-color: #ff5a7d;
	color: #fff
}

.theme-blush .nav-tabs-new>li>a.active {
	border-color: #ff5a7d !important;
	background: #ff5a7d;
	color: #fff
}

.theme-blush .fancy-checkbox input[type="checkbox"]:checked+span:before {
	background: #ff5a7d;
	border-color: #ff5a7d
}

.theme-blush .chat-widget li.right .chat-info {
	background: #ff5a7d;
	color: #fff
}

.theme-blush .chat-widget li.right .chat-info:before {
	border-left: 10px solid #ff5a7d
}

.theme-blush .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header {
	background: #ff5a7d;
	border-color: #ff5a7d;
	color: #fff
}

.theme-blush .blog-page .single_post .footer .stats li a:hover {
	color: #ff5a7d
}

.theme-blush .auth-main .btn-primary {
	background: #ff5a7d;
	border-color: #ff5a7d
}

.theme-blush .sidebar-nav .metismenu>li i {
	color: #ff5a7d
}

.theme-blush .right_chat li a:hover .media-object {
	border-color: #ff5a7d
}

.theme-blush .chat_app .chat .chat-history .other-message {
	background: #ff5a7d;
	color: #fff
}

.theme-blush .chat_app .chat .chat-history .other-message::after {
	border-bottom-color: #ff5a7d
}

.theme-blush .chat_app .people_list .chat-list li.active,
.theme-blush .chat_app .people_list .chat-list li:hover {
	border-color: #ff5a7d
}